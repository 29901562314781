import React, { useContext, useState } from "react";
import Button from "../reusable/Button";
import Input from "../reusable/Input";
import { BaseURL } from "../BaseURL";
import toast from "react-hot-toast";
import { LuEye } from "react-icons/lu";
import { LuEyeOff } from "react-icons/lu";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../UserContext";
import { motion } from "framer-motion";
import GetLocalStorageValue from "../reusable/GetLocalStorageValue";

import WhiteBlueLogo from "../../img/logo-white-blue.webp";
import BlueBlackLogo from "../../img/logo-blue-black.webp";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import { SignOutButton } from "../../msal-login/msal-login-buttons/SignOutButton";
import { SignInButton } from "../../msal-login/msal-login-buttons/SignInButton";
import msLogo from "../../img/ms-logo2.png";
import OutsideClickHandler from "react-outside-click-handler";
import { loginRequest } from "../../msal-login/auth-files/authConfig";

export default function Login() {
  const {
    setResToken,
    darkTheme,
    tokenMode,
    setTokenMode,
    setCodeNavigatorToken,
    setUserLoginInfo,
    userLoginInfo,
    isMsalLogin,
    setIsMsalLogin,
  } = useContext(UserContext);
  const [password, setPassword] = useState("");
  const [email, setEmail] = useState("");
  const [toggle, setToggle] = useState("password");
  const [submitDisable, setSubmitDisable] = useState(false);

  const [isMsalLoginBtnOpen, setIsMsalLoginBtnOpen] = useState(false);

  const navigate = useNavigate();

  // MSAL LIB
  const isAuthenticated = useIsAuthenticated();
  const { instance } = useMsal();

  const loginFunc = async (e) => {
    e.preventDefault();
    if (!email) {
      toast.error("Enter Email or Username");
      return;
    }
    if (!password) {
      toast.error("Enter Password");
      return;
    }

    const loginPromise = new Promise(async (resolve, reject) => {
      setSubmitDisable(true);
      setTokenMode("");

      const loginData = {
        username: email,
        password: password,
      };
      const res = await fetch(`${BaseURL}/personal/login`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(loginData),
      });

      const resData = await res.json();
      if (resData.success) {
        if (resData.data.access_token) {
          setTokenMode("personal");
          // toast.success("Logged In");

          const storeInLocalStorage = {
            user_id: resData.data.user_id,
            user_name: resData.data.user_name,
          };
          localStorage.setItem("uInfo", JSON.stringify(storeInLocalStorage));
          const gettingLocalStorageData = await GetLocalStorageValue(
            "uInfo",
            {}
          );
          await setUserLoginInfo(gettingLocalStorageData);
          // navigate("/");

          setIsMsalLogin(false);
          localStorage.removeItem("msInfo");
          localStorage.removeItem("previousUrl");
          localStorage.removeItem("currentUrl");
          localStorage.setItem("access_token", resData.data.access_token);
          localStorage.removeItem("cn_access_token");
          localStorage.removeItem("cn_rt");
          setCodeNavigatorToken("");
          setResToken(resData.data.access_token);

          setPassword("");
          setEmail("");

          navigate("/");
          setSubmitDisable(false);
        } else {
          toast.error("Session issues");
          setSubmitDisable(false);
        }
        resolve();
      } else {
        // toast.error("Wrong Credentials !");
        setSubmitDisable(false);
        navigate("/");
        reject();
      }
    });

    const gettingLocalStorageData = await GetLocalStorageValue("uInfo", {});
    await toast.promise(loginPromise, {
      loading: "Loading..",
      success: `Logged In`,
      // success: `Welcome ${gettingLocalStorageData.user_name}`,
      error: "Wrong Credentials !",
    });
  };

  const handleMSLogin = (loginType) => {
    if (loginType === "popup") {
      setIsMsalLogin(true);

      instance
        .loginPopup(loginRequest)
        .then((res) => {
          if (res.accessToken) {
            navigate("/authentication");
          }
        })
        .catch((e) => {
          console.log("msal_loginPopup-error==>", e);
        });
    } else if (loginType === "redirect") {
      setIsMsalLogin(true);

      instance.loginRedirect(loginRequest).catch((e) => {
        console.log(e);
      });
    }
  };
  return (
    <motion.div
      className="px-3 flex items-center justify-center HeightHome flex-col gap-y-4"
      initial={{ opacity: 0, y: 25 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 1, y: 25 }}
      transition={{ delay: 0.3, type: "easeInOut", duration: 0.7 }}
    >
      <div className="w-full md:w-96 rounded-xl">
        <div className="flex items-center justify-center mb-4 md:mb-6">
          {darkTheme ? (
            <img
              className="w-28 md:w-32"
              // src="./assets/logo-white.webp"
              src={WhiteBlueLogo}
              alt="logo"
            />
          ) : (
            <img className="w-28 md:w-32" src={BlueBlackLogo} alt="logo" />
          )}
        </div>

        <form>
          <div className="">
            <Input
              type="email"
              placeholder="Email or Username"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className={"w-full my-1"}
            />
            <div className="relative">
              <Input
                type={toggle}
                placeholder="Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                className={"w-full my-1"}
              />

              {toggle === "password" ? (
                <LuEye
                  className="w-5 h-5 text-gray absolute right-2 top-[11px]"
                  onClick={() => setToggle("text")}
                />
              ) : (
                <LuEyeOff
                  className="w-5 h-5 text-gray absolute right-2 top-3"
                  onClick={() => setToggle("password")}
                />
              )}
            </div>
          </div>
          <button
            disabled={submitDisable}
            type="submit"
            className={`px-11 py-2 rounded-full bg-lighGray text-Black transition-all font-medium font-Barlow_medium my-3 w-full ${
              submitDisable ? "" : "hover:bg-White active:bg-lighGray"
            }`}
            onClick={(e) => loginFunc(e)}
          >
            <div>{submitDisable ? "..." : "Login"}</div>
          </button>

          {/* MSAL LOGIN BTN  */}
          <OutsideClickHandler
            onOutsideClick={() => setIsMsalLoginBtnOpen(false)}
          >
            <button
              type="button"
              className={`w-full flex items-center justify-center text-Black gap-2 transition-all hover:bg-White active:bg-lighGray bg-lighGray py-2 px-3 rounded-full font-medium font-Barlow_medium`}
              // onClick={() => setIsMsalLoginBtnOpen(!isMsalLoginBtnOpen)}
              onClick={() => handleMSLogin("popup")}
            >
              Sign In with
              <img className={`w-6`} src={msLogo} alt="ms-logo" />
            </button>
            {/* <div>
              {isMsalLoginBtnOpen && (
                <div
                  className={`absolute z-20 py-2 px-4 rounded-xl mt-2 ${
                    darkTheme ? "bg-cfBlue" : "bg-White"
                  }`}
                >
                  {isAuthenticated ? <SignOutButton /> : <SignInButton />}
                </div>
              )}
            </div> */}
          </OutsideClickHandler>
        </form>
      </div>
    </motion.div>
  );
}
