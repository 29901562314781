import { useMsal } from "@azure/msal-react";
import { useContext, useEffect, useState } from "react";
import { callMsGraph } from "./auth-files/graph";
import { loginRequest } from "./auth-files/authConfig";
import { UserContext } from "../components/UserContext";
import GetLocalStorageValue from "../components/reusable/GetLocalStorageValue";
import { useNavigate } from "react-router-dom";
import { BaseURL } from "../components/BaseURL";
import toast from "react-hot-toast";

export const ProfileContent = () => {
  const {
    graphData,
    setGraphData,
    setUserLoginInfo,
    setCodeNavigatorToken,
    setTokenMode,
    setResToken,
    setIsMsalLogin,
  } = useContext(UserContext);
  const { instance, accounts } = useMsal();
  //   const [graphData, setGraphData] = useState(null);

  const [msalAccessToken, setMsalAccessToken] = useState("");
  const [msalLoginData, setMsalLoginData] = useState({});
  const navigate = useNavigate();

  const getMsalInfoFunc = async () => {
    await msalLoginFunc();
  };
  useEffect(() => {
    RequestProfileData();
  }, []);
  useEffect(() => {
    if (graphData?.userPrincipalName) {
      getMsalInfoFunc();
    }
  }, [graphData?.userPrincipalName]);

  function RequestProfileData() {
    // Silently acquires an access token which is then attached to a request for MS Graph data
    instance
      .acquireTokenSilent({
        ...loginRequest,
        account: accounts[0],
      })
      .then((response) => {
        setMsalAccessToken(response.accessToken);

        callMsGraph(response.accessToken).then(async (response) => {
          setGraphData(response);

          // const storeInLocalStorage ={
          //   givenName: response.givenName,
          //   userPrincipalName: response.userPrincipalName,
          // }
          // localStorage.setItem("msInfo", JSON.stringify(storeInLocalStorage));

          localStorage.setItem("msInfo", JSON.stringify(response));
          // const gettingLocalStorageMSData = await GetLocalStorageValue("msInfo", {});
          // await setGraphData(gettingLocalStorageMSData);

          // console.log("profile-content-GrapData-res==>>", response);
        });
        // console.log("profile-content-res==>>", response);
        // console.log("profile-content-resAccessToken==>>", response.accessToken);
      });
  }

  const msalLoginFunc = async (e) => {
    // const loginPromise = new Promise(async (resolve, reject) => {
    setTokenMode("");

    const loginData = {
      username: graphData?.userPrincipalName,
      name: graphData?.givenName,
      email: graphData?.userPrincipalName,
    };
    // console.log("ms-profi-loggggindata==>", loginData);
    const res = await fetch(`${BaseURL}/ms/login`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(loginData),
    });

    const resData = await res.json();
    if (resData.success) {
      if (resData.data.access_token) {
        toast.success("Logged In");
        localStorage.setItem("access_token", resData.data.access_token);
        localStorage.removeItem("cn_access_token");
        setCodeNavigatorToken("");
        setTokenMode("personal");
        setResToken(resData.data.access_token);

        const storeInLocalStorage = {
          user_id: resData.data.user_id,
          user_name: resData.data.user_name,
        };
        localStorage.setItem("uInfo", JSON.stringify(storeInLocalStorage));
        const gettingLocalStorageData = await GetLocalStorageValue("uInfo", {});
        await setUserLoginInfo(gettingLocalStorageData);
        setIsMsalLogin(false);
        navigate("/");
      } else {
        toast.error("Session issues");
      }
      // resolve();
    } else {
      toast.error("Wrong Credentials !");
      setIsMsalLogin(false);
      navigate("/");
      // reject();
    }
    // });

    // const gettingLocalStorageData = await GetLocalStorageValue("uInfo", {});
    // await toast.promise(loginPromise, {
    //   loading: "Loading..",
    //   success: `Logged In`,
    //   // success: `Welcome ${gettingLocalStorageData.user_name}`,
    //   error: "Wrong Credentials !",
    // });
  };

  return (
    <>
      {/* <h5 className="card-title">Welcome {accounts[0].name}</h5>
      {graphData ? (
        // <ProfileData graphData={graphData} />
        // <div graphData={graphData} />
        <span>dddd logged in</span>
      ) : (
        <button onClick={RequestProfileData}>
          Request Profile Information
        </button>
      )} */}

      {/* <div className="flex items-center justify-center h-screen px-4">
        
        <div class={`loading`}>
          <span class="letter text-cfBlue">C</span>
          <span class="letter text-cfBlue">l</span>
          <span class="letter text-cfBlue">o</span>
          <span class="letter text-cfBlue">u</span>
          <span class="letter text-cfBlue">d</span>
          <span class="letter">F</span>
          <span class="letter">r</span>
          <span class="letter">a</span>
          <span class="letter">m</span>
          <span class="letter">e</span>
        </div>
      </div> */}

      <div className={`h-screen px-4 flex items-center justify-center flex-col gap-4`}>
        <div className={`my-6 font-medium font-Barlow_medium text-lg`}>
          Welcome {accounts[0]?.name}
        </div>
        <div>Please wait..</div>
      </div>
    </>
  );
};
