import { useContext, useEffect, useRef, useState } from "react";
import { createGlobalStyle } from "styled-components";
import { UserContext } from "./components/UserContext";
import {
  Route,
  BrowserRouter as Router,
  Routes,
  useNavigate,
} from "react-router-dom";
import { Callback } from "./components/Oauth2/Callback";
import Login from "./components/pages/Login";
import PageNotFound from "./components/pages/PageNotFound";
import Layout from "./components/reusable/Layout";
import Home from "./components/pages/Home";
import { BaseURL } from "./components/BaseURL";
import toast from "react-hot-toast";
import "react-tippy/dist/tippy.css";
import OdPageNotFound from "./components/pages/OdPageNotFound";
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
} from "@azure/msal-react";
import { ProfileContent } from "./msal-login/ProfileContent";
import GetLocalStorageValue from "./components/reusable/GetLocalStorageValue";

const GlobalStyle = createGlobalStyle`
body{
  background: ${({ darkTheme }) => (darkTheme ? "#262626" : "#eff3f8")};
  color: ${(props) => (props.darkTheme ? "#E5E0D8" : "#000000")};
}
`;

function App() {
  const {
    darkTheme,
    setDarkTheme,
    setIsSearchOpen,
    allPosts,
    setAllPosts,
    setBodyData,
    bodyData,
    selectedHighlight,
    setSelectedHighlight,
    resToken,
    setResToken,
    loginDataLoading,
    codeNavigatorToken,
    tokenMode,
    setCodeNavigatorToken,
    setOpenMenu,
    setBreadCrumbCategory,
    setBreadCrumb,
    setUrlTagIdStore,
    setInitialLeftScrollTrue,
    leftScrollFlag,
    setLeftScrollFlag,
    setPostId,

    setIsOpenLink,
    isOpenLink,
    isMsalLogin,
    setIsMsalLogin,
    setAllowFeedback,
    setCnUserInfo,
    cnUserInfo,
  } = useContext(UserContext);
  const [postLoader, setPostLoader] = useState(true);

  // const [isCNtoken, setIsCNtoken] = useState(false);

  // const navigate = useNavigate();
  // THEME toggle by SYSTEM
  const mediaQuery = window.matchMedia("(prefers-color-scheme: dark)");
  useEffect(() => {
    const handleChange = (e) => {
      // setDarkTheme(mediaQuery.matches ? 'dark' : 'light');
      setDarkTheme(e.matches ? true : false);
    };

    // Listen for system theme changes
    mediaQuery.addEventListener("change", handleChange);

    return () => mediaQuery.removeEventListener("change", handleChange);
  }, [mediaQuery?.matches]);

  // ACCESS TOKEN
  const accessToken = localStorage.getItem("access_token");
  // CN ACCESS TOKEN
  const cnAccessToken = localStorage.getItem("cn_access_token");

  const hasMounted = useRef(false);

  const url = window.location.href;
  // const endPath = url.split("/").pop();
  // let codeNavigatorSlugLogin = url.includes("/auth?token=")
  let codeNavigatorSlugLogin = url.includes("/auth?token=")
    ? false
    : url.includes("token=")
    ? true
    : false;

  if (url.includes("/auth?token=")) {
    localStorage.removeItem("cn_access_token");
  }

  // console.log("cnSlugLogin===>", codeNavigatorSlugLogin);

  // const cleanUrl = url.split("#")[0];
  // const endPath = cleanUrl.split("/").pop();
  // const tagIdUrl = url.split("#")[1] || "";   // 15 nov 2024

  // const endPath = url.split("/").pop();

  // const cleanUrl = url.split("#")[0];
  // const endPath = cleanUrl.split("/").pop();
  // const tagIdUrl = url.split("#")[1] || "";

  const cleanUrl = url.split("#")[0];
  // expressions for IDENTIFICATION
  const parsedUrl = new URL(cleanUrl);
  // Get the path and remove the leading "/"
  // const pathWithoutLeadingSlash = parsedUrl.pathname.slice(1);
  // const endPath1 = parsedUrl.pathname.slice(1);
  const endPath1 = parsedUrl.pathname.slice(1);
  // console.log("end-path-1==>", endPath1);
  if (codeNavigatorSlugLogin) {
    localStorage.removeItem("cn_access_token");
    localStorage.removeItem("cn_rt");
    // setIsCNtoken(true)
    const slugByCN = endPath1;
    // const slugByCN = endPath1.slice(0, -5);
    localStorage.setItem("sByCN", slugByCN);

    // console.log("slugByCN===>", slugByCN);
  }
  if (tokenMode === "codenavigator") {
    const cnSlug = localStorage.getItem("sByCN");
    codeNavigatorSlugLogin = false;
    // setIsCNtoken(true)
    // localStorage.removeItem("sByCN");
  }
  // const endPath = codeNavigatorSlugLogin
  //   ? endPath1.slice(0, -5)
  //   : parsedUrl.pathname.slice(1);
  const endPath =
    tokenMode === "codenavigator" || codeNavigatorSlugLogin
      ? codeNavigatorSlugLogin
        ? endPath1
        : localStorage.getItem("sByCN")
        ? localStorage.getItem("sByCN")
        : parsedUrl.pathname.slice(1)
      : parsedUrl.pathname.slice(1);
  const tagIdUrl = url.split("#")[1] || ""; // 19 nov codes 2024

  // console.log("end-path==>", endPath);

  if (tagIdUrl) {
    setUrlTagIdStore(tagIdUrl);
  }

  const cnAccessPath = new URLSearchParams(window.location.search);
  const queryByCN = cnAccessPath.get("token");

  // GETTING ALL POST
  const getAllPostFunc = async () => {
    setPostLoader(true);
    setResToken("");
    setCodeNavigatorToken("");

    if (url.includes("/od")) {
      setIsOpenLink("od");

      // OPEN LINK FETCHING
      const res = await fetch(`${BaseURL}/od/posts`, {
        method: "GET",
        headers: {
          Accept: "application/json",
          // Authorization: `Bearer ${
          //   tokenMode === "personal" ? accessToken : cnAccessToken
          // }`,
        },
      });
      const data = await res.json();

      if (data?.success) {
        setPostLoader(true);

        // await setAllPosts(data.data);
        // COUNTS hEADING using DOMparser
        const parseHTMLAndCountHeadings = (dataArray) => {
          return dataArray.map((user) => {
            // Map through each user's posts
            const updatedPosts = user.posts.map((post) => {
              const parser = new DOMParser();
              const doc = parser.parseFromString(post.body, "text/html");

              // Count all headings h1, h2, h3, and h4
              const count = {
                h1: doc.getElementsByTagName("h1").length,
                // h2: doc.getElementsByTagName("h2").length,
                // h3: doc.getElementsByTagName("h3").length,
                // h4: doc.getElementsByTagName("h4").length,
              };

              // Return a new post object with the count key added
              return {
                ...post,
                count,
              };
            });

            // Return a new user object with the updated posts
            return {
              ...user,
              posts: updatedPosts,
            };
          });
        };

        const newUpdatedData = await parseHTMLAndCountHeadings(data?.data);
        await setAllPosts(newUpdatedData);

        // console.log("new", newUpdatedData);
        // console.log("old", allPosts);
        // END HEADING COUNTS

        const updatedData = await data.data.map((item) => {
          return {
            ...item,
            posts: item.posts.map((post) => ({
              ...post,
              name: item.name,
            })),
          };
        });
        // console.log("myAllPoost", updatedData)
        const myAllPosts = await updatedData.flatMap(
          (category) => category.posts
        );
        if (endPath !== ("od" || "od/") && !queryByCN) {
          setPostLoader(true);
          // setAllPosts(data.data);
          // const myAllPosts = await data.data.flatMap(
          //   (category) => category.posts
          // );

          const slugByod = endPath.replace("od/", "");
          const filtered = await myAllPosts.filter(
            // (post) => post.slug === endPath
            (post) => post.slug === slugByod
          );

          if (filtered.length > 0) {
            setPostLoader(true);
            // setInitialLeftScrollTrue(true)

            await setBodyData(filtered[0].body);
            await setSelectedHighlight(filtered[0].slug);
            await setBreadCrumbCategory(filtered[0].name);
            await setBreadCrumb(filtered[0].title);
            await setPostId(filtered[0].id);
            // await setOpenMenu(0);
            await setResToken(accessToken || "");
            await setCodeNavigatorToken(cnAccessToken || "");

            setPostLoader(false);
            // setInitialLeftScrollTrue(false)
            setInitialLeftScrollTrue(true);
            setLeftScrollFlag(true);
          } else {
            // toast.error("Wrong address, Go to home address");
            toast.error("Oops! Incorrect Address. Go back to the Home Page");
            // navigate("/od/page-not-found");
          }
        } else {
          setPostLoader(true);
          // setInitialLeftScrollTrue(true)

          // setAllPosts(data.data);

          // await setBodyData(data.data[0].posts[0].body);
          // await setSelectedHighlight(data.data[0].posts[0].slug);
          // await setBreadCrumbCategory(data.data[0].posts[0].name);
          // await setBreadCrumb(data.data[0].posts[0].title);

          await setSelectedHighlight(myAllPosts[0].slug);
          await setBodyData(myAllPosts[0].body);
          await setBreadCrumbCategory(myAllPosts[0].name);
          await setBreadCrumb(myAllPosts[0].title);
          await setPostId(myAllPosts[0].id);

          // await setOpenMenu(0);
          await setResToken(accessToken || "");
          await setCodeNavigatorToken(cnAccessToken || "");

          setPostLoader(false);
          // setInitialLeftScrollTrue(false)
          setInitialLeftScrollTrue(true);
          setLeftScrollFlag(true);
        }
      } else if (data?.message === "Unauthenticated.") {
        setPostLoader(true);

        setAllPosts([]);
        setResToken("");
        setCodeNavigatorToken("");
        setPostLoader(false);
        setInitialLeftScrollTrue(false);
      } else {
        setPostLoader(true); //important
        setInitialLeftScrollTrue(false);

        setAllPosts([]);
        // toast.error("Unauthorized User !");
        setResToken("");
        setCodeNavigatorToken("");
        // setPostLoader(false);

        toast.error("Wrong address OR There is no internet connection");
      }
      if (bodyData) {
        setInitialLeftScrollTrue(false);
        setLeftScrollFlag(false);
      } else {
        setInitialLeftScrollTrue(false);
        setLeftScrollFlag(false);
      }
    } else {
      // WITH CREDENTIALS
      const res = await fetch(`${BaseURL}/${tokenMode}/posts`, {
        method: "GET",
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${
            tokenMode === "personal" ? accessToken : cnAccessToken
          }`,
        },
      });
      const data = await res.json();

      if (data?.success) {
        setPostLoader(true);

        // await setAllPosts(data.data);
        // COUNTS hEADING using DOMparser
        const parseHTMLAndCountHeadings = (dataArray) => {
          return dataArray.map((user) => {
            // Map through each user's posts
            const updatedPosts = user.posts.map((post) => {
              const parser = new DOMParser();
              const doc = parser.parseFromString(post.body, "text/html");

              // Count all headings h1, h2, h3, and h4
              const count = {
                h1: doc.getElementsByTagName("h1").length,
                // h2: doc.getElementsByTagName("h2").length,
                // h3: doc.getElementsByTagName("h3").length,
                // h4: doc.getElementsByTagName("h4").length,
              };

              // Return a new post object with the count key added
              return {
                ...post,
                count,
              };
            });

            // Return a new user object with the updated posts
            return {
              ...user,
              posts: updatedPosts,
            };
          });
        };

        const newUpdatedData = await parseHTMLAndCountHeadings(data?.data);
        await setAllPosts(newUpdatedData);

        // console.log("new", newUpdatedData);
        // console.log("old", allPosts);
        // END HEADING COUNTS

        const updatedData = await data.data.map((item) => {
          return {
            ...item,
            posts: item.posts.map((post) => ({
              ...post,
              name: item.name,
            })),
          };
        });
        // console.log("myAllPoost", updatedData)
        const myAllPosts = await updatedData.flatMap(
          (category) => category.posts
        );
        if (endPath && !queryByCN) {
          // if (endPath) {
          setPostLoader(true);
          // setAllPosts(data.data);
          // const myAllPosts = await data.data.flatMap(
          //   (category) => category.posts
          // );

          const filtered = await myAllPosts.filter(
            (post) => post.slug === endPath
          );

          if (filtered.length > 0) {
            setPostLoader(true);
            // setInitialLeftScrollTrue(true)

            localStorage.removeItem("sByCN");

            await setBodyData(filtered[0].body);
            await setSelectedHighlight(filtered[0].slug);
            await setBreadCrumbCategory(filtered[0].name);
            await setBreadCrumb(filtered[0].title);
            await setPostId(filtered[0].id);
            await setAllowFeedback(filtered[0].post_feedbacks_count);
            // await setOpenMenu(0);
            await setResToken(accessToken || "");
            await setCodeNavigatorToken(cnAccessToken || "");

            setPostLoader(false);
            // setInitialLeftScrollTrue(false)
            setInitialLeftScrollTrue(true);
            setLeftScrollFlag(true);
          } else {
            // toast.error("Wrong address, Go to home address");
            localStorage.removeItem("sByCN");
            toast.error("Oops! Incorrect Address. Going back to the Home Page");
            window.location.href = "/";
          }
        } else if (endPath && queryByCN) {
          setPostLoader(true);
          // setAllPosts(data.data);
          // const myAllPosts = await data.data.flatMap(
          //   (category) => category.posts
          // );

          const filtered = await myAllPosts.filter(
            (post) => post.slug === endPath
          );

          if (filtered.length > 0) {
            setPostLoader(true);
            // setInitialLeftScrollTrue(true)

            localStorage.removeItem("sByCN");

            await setBodyData(filtered[0].body);
            await setSelectedHighlight(filtered[0].slug);
            await setBreadCrumbCategory(filtered[0].name);
            await setBreadCrumb(filtered[0].title);
            await setPostId(filtered[0].id);
            await setAllowFeedback(filtered[0].post_feedbacks_count);
            // await setOpenMenu(0);
            await setResToken(accessToken || "");
            await setCodeNavigatorToken(cnAccessToken || "");

            setPostLoader(false);
            // setInitialLeftScrollTrue(false)
            setInitialLeftScrollTrue(true);
            setLeftScrollFlag(true);
          }
          // else {
          //   // toast.error("Wrong address, Go to home address");
          //   toast.error("Oops! Incorrect Address. Go back to the Home Page");
          // }
          else {
            setPostLoader(true);
            // setInitialLeftScrollTrue(true)

            localStorage.removeItem("sByCN");

            // setAllPosts(data.data);

            // await setBodyData(data.data[0].posts[0].body);
            // await setSelectedHighlight(data.data[0].posts[0].slug);
            // await setBreadCrumbCategory(data.data[0].posts[0].name);
            // await setBreadCrumb(data.data[0].posts[0].title);

            await setSelectedHighlight(myAllPosts[0].slug);
            await setBodyData(myAllPosts[0].body);
            await setBreadCrumbCategory(myAllPosts[0].name);
            await setBreadCrumb(myAllPosts[0].title);
            await setPostId(myAllPosts[0].id);
            await setAllowFeedback(myAllPosts[0].post_feedbacks_count);

            // await setOpenMenu(0);
            await setResToken(accessToken || "");
            await setCodeNavigatorToken(cnAccessToken || "");

            setPostLoader(false);
            // setInitialLeftScrollTrue(false)
            setInitialLeftScrollTrue(true);
            setLeftScrollFlag(true);
          }
        } else {
          setPostLoader(true);
          // setInitialLeftScrollTrue(true)

          localStorage.removeItem("sByCN");

          // setAllPosts(data.data);

          // await setBodyData(data.data[0].posts[0].body);
          // await setSelectedHighlight(data.data[0].posts[0].slug);
          // await setBreadCrumbCategory(data.data[0].posts[0].name);
          // await setBreadCrumb(data.data[0].posts[0].title);

          await setSelectedHighlight(myAllPosts[0].slug);
          await setBodyData(myAllPosts[0].body);
          await setBreadCrumbCategory(myAllPosts[0].name);
          await setBreadCrumb(myAllPosts[0].title);
          await setPostId(myAllPosts[0].id);
          await setAllowFeedback(myAllPosts[0].post_feedbacks_count);

          // await setOpenMenu(0);
          await setResToken(accessToken || "");
          await setCodeNavigatorToken(cnAccessToken || "");

          setPostLoader(false);
          // setInitialLeftScrollTrue(false)
          setInitialLeftScrollTrue(true);
          setLeftScrollFlag(true);
        }
      } else if (data?.message === "Unauthenticated.") {
        setPostLoader(true);

        setAllPosts([]);
        setResToken("");
        setCodeNavigatorToken("");
        setPostLoader(false);
        setInitialLeftScrollTrue(false);
      } else {
        setPostLoader(true); //important
        setInitialLeftScrollTrue(false);

        setAllPosts([]);
        // toast.error("Unauthorized User !");
        setResToken("");
        setCodeNavigatorToken("");
        // setPostLoader(false);

        toast.error("Wrong address OR There is no internet connection");
      }
      if (bodyData) {
        setInitialLeftScrollTrue(false);
        setLeftScrollFlag(false);
      } else {
        setInitialLeftScrollTrue(false);
        setLeftScrollFlag(false);
      }
    }
  };

  // useEffect(() => {
  //   if (!hasMounted.current) {
  //     hasMounted.current = true;
  //     return;
  //   }

  //   getAllPostFunc();
  // }, [codeNavigatorToken || resToken]);

  useEffect(() => {
    if (!hasMounted.current) {
      hasMounted.current = true;
      return;
    }
    // if (tokenMode || codeNavigatorToken) {
    //   getAllPostFunc();
    // }

    if (isOpenLink) {
      getAllPostFunc();
    } else {
      if (tokenMode || codeNavigatorToken) {
        getAllPostFunc();
      }
    }
  }, [tokenMode, isOpenLink]);

  // GETTING CN_ACCESS TOKEN FROM REFRESH TOKEN
  useEffect(() => {
    if (allPosts.length > 0) {
      // TOKEN EXPIRATION TIME day | year | HOURS
      const personalVerify = async () => {
        const res = await fetch(`${BaseURL}/${tokenMode}/verify`, {
          method: "GET",
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${
              tokenMode === "personal" ? accessToken : cnAccessToken
            }`,
          },
        });
        const resData = await res.json();
        const msg = resData?.message;
        if (msg === "Unauthenticated.") {
          const getCNaccessTokenFromRefreshToken = async () => {
            const rToken = sessionStorage.getItem("cn_rtoken");
            const res = await fetch(
              `${BaseURL}/get-accesstoken-from-refreshtoken`,
              {
                headers: {
                  Accept: "application/json",
                  "Content-Type": "application/json",
                },
                method: "POST",
                body: JSON.stringify({ refresh_token: rToken }),
                // body: "",
                // credentials: "include",
              }
            );

            const data = await res.json();

            if (data?.access_token) {
              if (localStorage.getItem("cn_access_token")) {
                localStorage.setItem("cn_access_token", data.access_token);
              } else if (localStorage.getItem("access_token")) {
                localStorage.setItem("access_token", data.access_token);
              }
              sessionStorage.setItem("cn_rtoken", data.refresh_token);
              setCodeNavigatorToken(data.access_token);
              // setCodeNavigatorToken("");
              // setResToken("");

              // Date: 12 Dec, 2024
              // Ref: Email/Meeting on 11 Dec, 2024 from Manigandan to add cn Username and version.
              // Changes: Storing cn Username and version.
              if (data?.customer_name || data?.platform_version) {
                const userInfoFromCN = {
                  customer_name: data.customer_name || "",
                  platform_version: data.platform_version || "",
                };
                localStorage.setItem(
                  "cn_userInfo",
                  JSON.stringify(userInfoFromCN)
                );
                const gettingCnUserInfoData = await GetLocalStorageValue(
                  "cn_userInfo",
                  {}
                );
                setCnUserInfo(gettingCnUserInfoData);
                // setCnUserInfo(userInfoFromCN);
              } else {
                localStorage.removeItem("cn_userInfo");
              }
            }
          };
          getCNaccessTokenFromRefreshToken();
        }
      };
      const interval = setInterval(() => {
        // if (!isOpenLink) {
        personalVerify();
        // }
      }, 120000);
      return () => clearInterval(interval);
    }
    // }, [(accessToken && resToken) || (cnAccessToken && codeNavigatorToken)])
  }, [allPosts.length > 0]);

  // useEffect(() => {
  //   if (allPosts.length > 0) {
  //     // TOKEN EXPIRATION TIME day | year | HOURS
  //     const personalVerify = async () => {
  //       const res = await fetch(`${BaseURL}/${tokenMode}/verify`, {
  //         method: "GET",
  //         headers: {
  //           Accept: "application/json",
  //           Authorization: `Bearer ${
  //             tokenMode === "personal" ? accessToken : cnAccessToken
  //           }`,
  //         },
  //       });
  //       const resData = await res.json();
  //       const msg = resData?.message;
  //       if (msg === "Unauthenticated.") {
  //         localStorage.removeItem("access_token");
  //         localStorage.removeItem("cn_access_token");
  //         setAllPosts([]);
  //         setResToken("");
  //         setCodeNavigatorToken("");
  //         toast.error("Session has been expired !");

  //         const goingLoginFunc = () => {
  //           setResToken("");
  //           setCodeNavigatorToken("");
  //           localStorage.removeItem("access_token");
  //           localStorage.removeItem("cn_access_token");
  //           window.location.reload();
  //         };
  //         return (
  //           <div className="flex items-center justify-center flex-col h-screen px-3 gap-y-4">
  //             {/* <div className={`text-lg`}>404</div> */}
  //             <div className="{`text-lg`}">Session has been expired.</div>
  //             <button
  //               type="submit"
  //               className={`px-11 py-2 rounded-full bg-lighGray text-Black flex items-center gap-1 flex-wrap transition-all font-medium font-Barlow_medium hover:bg-White active:bg-opacity-60`}
  //               onClick={(e) => goingLoginFunc(e)}
  //             >
  //               Go to Login Page
  //             </button>
  //           </div>
  //         );
  //       }
  //     };
  //     const interval = setInterval(() => {
  //       if (!isOpenLink) {
  //         personalVerify();
  //       }
  //     }, 120000);
  //     return () => clearInterval(interval);
  //   }
  //   // }, [(accessToken && resToken) || (cnAccessToken && codeNavigatorToken)])
  // }, [allPosts.length > 0]);

  // console.log("allPosts", allPosts);

  // useEffect(() => {
  //   const myAllPosts = allPosts.flatMap(user => user.posts);
  //   const filtered = myAllPosts.filter(post => post.slug.includes(`${endPath}`));

  //   console.log("filterData",filtered);

  //   // setBodyData(filtered[0]?.body)
  //   // setSelectedHighlight(filtered[0]?.slug)

  // }, [allPosts?.length>0 && endPath]);

  // ctrl + k Keyboard =======
  const handleKeyPress = (event) => {
    if (event.ctrlKey && event.key === "k") {
      event.preventDefault();
      setIsSearchOpen((prevVisible) => !prevVisible);
    }
  };
  useEffect(() => {
    window.addEventListener("keydown", handleKeyPress);

    return () => {
      window.removeEventListener("keydown", handleKeyPress);
    };
  }, []);

  if (postLoader || loginDataLoading) {
    return (
      // <div className="flex items-center justify-center h-screen">Loading..</div>
      <div className="flex items-center justify-center h-screen px-4">
        <div class={`loading`}>
          <span class="letter text-cfBlue">C</span>
          <span class="letter text-cfBlue">l</span>
          <span class="letter text-cfBlue">o</span>
          <span class="letter text-cfBlue">u</span>
          <span class="letter text-cfBlue">d</span>
          <span class="letter">F</span>
          <span class="letter">r</span>
          <span class="letter">a</span>
          <span class="letter">m</span>
          <span class="letter">e</span>
        </div>
      </div>
    );
  }

  // // heading count using DOMparser
  // const parseHTMLAndCountHeadings = (dataArray) => {
  //   return dataArray.map((user) => {
  //     // Map through each user's posts
  //     const updatedPosts = user.posts.map((post) => {
  //       const parser = new DOMParser();
  //       const doc = parser.parseFromString(post.body, "text/html");

  //       // Count all headings h1, h2, h3, and h4
  //       const count = {
  //         h1: doc.getElementsByTagName("h1").length,
  //         h2: doc.getElementsByTagName("h2").length,
  //         h3: doc.getElementsByTagName("h3").length,
  //         h4: doc.getElementsByTagName("h4").length,
  //       };

  //       // Return a new post object with the count key added
  //       return {
  //         ...post,
  //         count,
  //       };
  //     });

  //     // Return a new user object with the updated posts
  //     return {
  //       ...user,
  //       posts: updatedPosts,
  //     };
  //   });
  // };

  // const newUpdatedData = parseHTMLAndCountHeadings(allPosts);

  // console.log("new",newUpdatedData);
  // console.log("old",allPosts);

  return (
    // <div className="max-w-[1440px] mx-auto relative">
    <div className="relative">
      <GlobalStyle darkTheme={darkTheme} />

      <div className="max-w-[1740px] mx-auto ">
        {/* START PROFILE MSAL AUTH  */}

        {/* <AuthenticatedTemplate>
          <ProfileContent />
        </AuthenticatedTemplate>

        <UnauthenticatedTemplate>
          <h5 className="card-title">
            Please sign-in to see your profile information.
          </h5>
        </UnauthenticatedTemplate> */}

        {isMsalLogin ? (
          <>
            <Router>
              <Routes>
                <Route
                  path="/authentication"
                  element={
                    <AuthenticatedTemplate>
                      <ProfileContent />
                    </AuthenticatedTemplate>
                  }
                />

                <Route
                  path="/"
                  element={
                    <UnauthenticatedTemplate>
                      {/* <h5 className="card-title">
                        Please sign-in to see your profile information.
                      </h5> */}
                      <Login />
                    </UnauthenticatedTemplate>
                  }
                />
              </Routes>
            </Router>
          </>
        ) : (
          <Router>
            {/* <Layout /> */}

            <Routes>
              {/* login with cn - with - slug */}
              {codeNavigatorSlugLogin === false ? (
                <>
                  {isOpenLink ? (
                    <>
                      <Route path="/" element={<Layout />}>
                        <Route path="/*" element={<Home />} />
                        <Route index element={<Home />} />
                        {/* <Route
                      path="/od/page-not-found"
                      element={<OdPageNotFound />}
                    /> */}
                      </Route>
                    </>
                  ) : (
                    <>
                      {codeNavigatorToken !== "" || resToken !== "" ? (
                        <Route path="/" element={<Layout />}>
                          <Route path="/*" element={<Home />} />
                          <Route index element={<Home />} />
                        </Route>
                      ) : (
                        <>
                          <Route path="/" element={<Login />} />
                          <Route path="/*" element={<PageNotFound />} />
                        </>
                      )}
                    </>
                  )}
                </>
              ) : (
                <>
                  <Route
                    // path={endPath ? `/${endPath}/auth` : "/auth"}
                    // path={endPath ? `/${endPath}` : "/auth"}
                    path={`/${endPath}`}
                    element={<Callback />}
                  />
                </>
              )}
              <>
                <Route path={"/auth"} element={<Callback />} />
              </>
            </Routes>
          </Router>
        )}

        {/* END PROFILE MSAL AUTH  */}
      </div>
    </div>
  );
}

export default App;
