import React, { useContext } from "react";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import { UserContext } from "../UserContext";
import { motion } from "framer-motion";

export const CustomConfirmDialog = ({
  title,
  message,
  onConfirm,
  onCancel,
}) => {
  const { darkTheme } = useContext(UserContext);
  return (
    <motion.div
      className={`w-60 md:w-72 m-2 px-8 py-4 rounded-xl bg-[#e2e2e2]`}
      initial={{ opacity: 0, y: 25 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 1, y: 25 }}
      transition={{ delay: 0.3, type: "easeInOut", duration: 0.7 }}
    >
      <div className={`flex items-center flex-col`}>
        <h3 className="custom-title text-lg text-Black font-medium">
          {title}
        </h3>
        <p className="text-Black mt-2 text-[13px]">{message}</p>
        <div className={`flex items-center gap-4 mt-6`}>
          <button
            onClick={onConfirm}
            className={`active:bg-gray px-4 py-1 rounded-full transition-all bg-eee hover:bg-White text-Black`}
          >
            Yes
          </button>
          <button
            onClick={onCancel}
            className={`active:bg-gray px-4 py-1 rounded-full transition-all bg-eee hover:bg-White text-Black`}
          >
            No
          </button>
        </div>
      </div>
    </motion.div>
  );
};

// const showDialog = () => {
//   confirmAlert({
//     customUI: ({ onClose }) => {
//       return (
//         <CustomConfirmDialog
//           title='Are you sure?'
//           message='You want to delete this file?'
//           onConfirm={() => {
//             onClose();
//             // Handle confirm action
//           }}
//           onCancel={onClose}
//         />
//       );
//     },
//   });
// };

// const App = () => {
//   return (
//     <div>
//       <button onClick={showDialog}>Show Confirm Dialog</button>
//     </div>
//   );
// };
